
export default {
  data() {
    return {
      list: [],
      item999: {},
      matchItem: {}
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.$fetch({
        url: '/dpr/leaderboard1',
        params: this.$route.query
      }).then(res => {
        console.log(res)
        this.list = res.data.list;
        this.matchItem = res.data.match
      })
    }
  }
}
